import React from "react";
import ReactMarkdown from "react-markdown";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  GlobalStyle,
  Center,
  Spacer,
  VerySmall,
  TitleBar,
  Link,
  Bold,
  PageContent,
  SponsorImgContainer,
  SponsorsContainer,
  MonkeyContainer,
  Logos,
  Monkey,
  CommunityHub1,
  CommunityHub2,
  CommunityHub3,
  CommunityHub4,
} from "./Components";
import rehypeRaw from "rehype-raw";
// @ts-ignore
import headingId from "remark-heading-id";
import remarkGfm from 'remark-gfm'



/**
 * This is the root of the application.
 */
export function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/bogota-agenda" exact component={DevconAgendaPage} />
          <Route path="/bogota-community-hub" exact component={DevconCommunityHub} />
          <Route path="/stanford-agenda" exact component={StanfordAgendaPage} />
          <Route path="/amsterdam-agenda" exact component={AmsterdamAgendaPage} />
          {/*<Route path="/about" exact component={AboutPage} />*/}
          <Route path="*" component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

/**
 * Landing page of 0xPARC website, contains links to other pages.
 */
function LandingPage() {
  return (
    <Center>
      <Spacer height={4} />

      <Logos />

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <Bold>Applied ZK @ Devcon VI</Bold>
      </div>
      <div style={{ textAlign: "center" }}>
        Bogotá, Colombia
      </div>
      <div style={{ textAlign: "center" }}>
        October 11-14, 2022
      </div>
      
      <Spacer height={1} />

      <TitleBarLinks />

      <Spacer height={1} />

      <PageContent>
        <div style={{ textAlign: "left" }}>
        <Bold>Join 0xPARC and EF Privacy & Scaling Explorations at Devcon VI for a series of demos, talks, and panels discussing ZK technology.</Bold>
        </div>
        <Spacer height={1}/>
        <div style={{ textAlign: "left" }}>
          The Applied ZK SLS brings teams from around the ZK ecosystem together to learn about the latest developments in the zero-knowledge application landscape. We'll be running:
          <ul>
            <li>
              A <a href="/bogota-agenda">full-day ZK workshop</a> on Thursday 10/13 (Floor 2, Workshop 1). You can add each of the three subsessions to your Devcon calendar here: <a href="https://app.devcon.org/schedule/KPR8CL">1</a>, <a href="https://app.devcon.org/schedule/8QFEHB">2</a>, <a href="https://app.devcon.org/schedule/xmhca7">3</a>.
            </li>
            <li>
              A continuous <a href="/bogota-community-hub">ZK Community Hub</a> from Tuesday 10/11 through Friday 10/14 (Floor 1, Community Hubs area).
            </li>
          </ul>
        </div>
        <Spacer height={1}/>
        <div style={{ textAlign: "left" }}>
          "Applications of ZK" include dapps such as <a href="https://zkga.me">Dark Forest</a> and <a href="https://tornado.cash/">TornadoCash</a>, or primitives such as <a href="https://semaphore.appliedzkp.org/">Semaphore</a> and <a href="https://appliedzkp.github.io/maci/">MACI</a>. Particular topics of focus include:
          <ul>
            <li>
              <Bold>ZK application design patterns</Bold>: Techniques and patterns for building user-facing applications enabled by ZK.
            </li>
            <li>
              <Bold>Future of proving systems</Bold>: ZK developer tools and the future of proving systems, languages, and more.
            </li>
            <li>
              <Bold>Auditing and verification</Bold>: Formal verification and other methods for ensuring the correctness of complex ZK constructions.
            </li>
          </ul>
        </div>
        {/* <div style={{textAlign: "left"}}>
          The event will take place from 10AM - 5PM each day, and will feature talks, demos, workshops, and breakout discussion sessions.
          Saturday, April 23 will focus on ZK applications, while Sunday, April 24 will focus primarily on tooling and infrastructure. For more details, see the <Link href="/agenda">program agenda</Link>.
        </div>
        <Spacer height={1}/> */}
        <div>
          Visit these links to learn more about <a href="https://medium.com/privacy-scaling-explorations/about">EF Privacy and Scaling Explorations</a> and <a href="https://0xparc.org">0xPARC</a>.
        </div>
      </PageContent>

      <Spacer height={4} />

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  );
}

function DevconAgendaPage() {
  const morning = [
    {
      title: "Opening Remarks",
      speaker: "Ying Tong Lai (0xPARC / ECC), Barry Whitehat (EF PSE)",
      time: "10:00AM",
      description: ""
    },
    {
      title: "ZK Circuits for Elliptic Curve Operations in Halo2",
      speaker: "Yi Sun (Axiom)",
      time: "10:10AM",
      description: "We present halo2-ecc, a ZK circuit library for elliptic curve operations in halo2. Our library implements multi-scalar multiplication, optimal Ate pairing, and ECDSA verification on curves including BN254 and Secp256k1. We will show benchmarks for these operations as well as applications to recursion and aggregation of halo2 proofs.  This is joint work with Jonathan Wang."
    },
    {
      title: "Proving System Challenges from ZKML",
      speaker: "Jason Morton (zKonduit / 0xPARC)",
      time: "10:20AM",
      description: "We discuss challenges and learnings from building deep learning models on Halo2."
    },
    {
      title: "Maze - Aggregation Tool for Circom-PLONK Proofs",
      speaker: "Janmajaya Mall (EF PSE)",
      time: "10:30AM",
      description: "The session aims to demo Maze, a CLI-based tool for aggregating several circom-plonk proofs into a single aggregated proof developed using Halo2 library. The session will also include a high level explanation of aggregation of plonk proofs using Proof Carrying Data (PCD)."
    },
    {
      title: "Cryptographic Optimizations in Aztec",
      speaker: "Adrian Hamelink (Aztec)",
      time: "10:40AM",
      description: ""
    },
    {
      title: "Optimizing Cryptographic Algorithms used in Gnark",
      speaker: "Youssef El Housni (Gnark)",
      time: "11:00AM",
      description: ""
    },
    {
      title: "[Panel] Next-Generation DSLs and IRs",
      speaker: "Ying Tong Lai (0xPARC / ECC), with Jordi B (Polygon Hermez), Louis G (Starkware), Brandon K (Mina), and Kev W (Aztec)",
      time: "11:20AM",
      description: "We discuss the future of higher-level langauges and circuit representations with developers from a number of toolstacks."
    },
    {
      title: "STARKs in SNARKs",
      speaker: "Jordi Baylina (Polygon Hermez)",
      time: "12:15PM",
      description: "We'll create a ZK circuit live, build a STARK, and build a SNARK that verifies the STARK."
    },
    {
      title: "Proof Systems for zkRollups",
      speaker: "Ye Zhang (Scroll)",
      time: "12:35PM",
      description: ""
    },
  ];

  const earlyAfternoon = [
    {
      title: "Opening Remarks",
      speaker: "Althea Allen (EF PSE)",
      time: "1:00PM",
      description: ""
    },
    {
      title: "SNARK Light Clients",
      speaker: "Uma Roy, John Guibas (Succinct Labs)",
      time: "1:05PM",
      description: "During this session, we'll go over basic components of Miden VM and describe how we've implemented them using the STARK proving system. We'll also provide a high-level overview of Miden VM features, and compare them against other ZK virtual machines."
    },
    {
      title: "Using ZKP for better p2p messaging with Waku",
      speaker: "Oskar Thoren (Vac)",
      time: "1:25PM",
      description: "In this short demo we'll look at Waku, the communication layer for Web3, and how it uses ZK for its service network. We'll demo using Waku with RLN for private, economic spam protection and what this means for you as a user and node operator. We'll also briefly look at how we can incentivize other services in the network in a private manner. Finally we'll show how you can actively participate in and contribute to the network yourself as a node operator, developer or end user."
    },
    {
      title: "Poseidon VM: a zkApp friendly VM with EVM compatibility",
      speaker: "Shumo Chu (Manta)",
      time: "1:40PM",
      description: "We present Poseidon VM, a blockchain VM designed to support decentralized applications powered by zero-knowledge proofs (a.k.a. zkApps) with friendly programming abstractions and cheap transaction cost. "
    },
    {
      title: "Break",
      speaker: "",
      time: "1:55PM",
      description: ""
    },
    {
      title: "Zero-Knowledge Machine Learning",
      speaker: "Jason Morton (zKonduit / 0xPARC)",
      time: "2:00PM",
      description: "ZKML could be the key to giving the blockchain eyes, letting smart contracts exercise judgment, one-person oracles, and generally getting data on-chain in a scalable way. I'll discuss applications, how to quickly convert PyTorch models to zero-knowledge proofs, and the roadmap."
    },
    {
      title: "Scaling up Trustless Neural Network Inference with Zero-Knowledge Proofs",
      speaker: "Yi Sun (Axiom)",
      time: "2:15PM",
      description: "We present the first ZK-SNARK proof of valid inference for a full resolution ImageNet model. We will describe the arithmetization and quantization optimizations enabling us to SNARK large neural networks as well as a software package enabling transpilation from off-the-shelf models to halo2 circuits. We design protocols using our circuits to verify machine learning model predictions and accuracy and present concrete estimates of overhead costs based on our circuit implementations. This is joint work with Daniel Kang, Tatsunori Hashimoto, and Ion Stoica."
    },
    {
      title: "Scaling up Trustless Neural Network Inference with Zero-Knowledge Proofs",
      speaker: "Yi Sun (Axiom)",
      time: "2:15PM",
      description: "We present the first ZK-SNARK proof of valid inference for a full resolution ImageNet model. We will describe the arithmetization and quantization optimizations enabling us to SNARK large neural networks as well as a software package enabling transpilation from off-the-shelf models to halo2 circuits. We design protocols using our circuits to verify machine learning model predictions and accuracy and present concrete estimates of overhead costs based on our circuit implementations. This is joint work with Daniel Kang, Tatsunori Hashimoto, and Ion Stoica."
    },
    {
      title: "Worldcoin",
      speaker: "Remco Bloeman (Worldcoin)",
      time: "2:30PM",
      description: ""
    },
    {
      title: "Break",
      speaker: "",
      time: "2:45PM",
      description: ""
    },
    {
      title: "Crypt-Keeper ZK Identity Wallet",
      speaker: "AtHeartEngineer and Iskander (EF PSE)",
      time: "2:50PM",
      description: "We demo the Crypt-Keeper identity commitment and zk proof generation wallet for semaphore, RLN, and other ZK identities."
    },
    {
      title: "Unirep Social - A demo application of Unirep protocol",
      speaker: "Vivian Jeng (EF PSE)",
      time: "3:00PM",
      description: "Unirep is a private reputation system. In this session we will use a social media app called Unirep Social to demo the Unirep protocol."
    },
    {
      title: "Zkitter - Anonymous Social Network",
      speaker: "0xTsukino (EF PSE)",
      time: "3:10PM",
      description: "A brief overview of Zkitter, an anonymous social network built using ZK technology. Learn how you can start posting and chatting anonymously while using your real life reputation."
    },
    {
      title: "heyanon.xyz",
      speaker: "Vivek Bhupatiraju (Personae Labs / 0xPARC)",
      time: "3:30PM",
      description: "Demoing heyanon, an open-source app allowing you to anonymously tweet as a verified member of on and off-chain groups. We’ll talk about how you can submit your own groups, our work on improving the ECDSA group experience, and our many ideas for extensions!"
    },
    {
      title: "zkemail: Decentralized ID Verification on Chain Without Servers",
      speaker: "Aayush Gupta, Sampriti Panda (Personae Labs / 0xPARC)",
      time: "3:45PM",
      description: "We demo zk-email, a new primitive that lets you verify emails trustlessly. We demo a simple trustless Twitter badge on chain, and show how almost any data in web2 can be turned into trustless badges or zk badges on a blockchain. Launching with Personae Labs!"
    },
  ];

  const lateAfternoon = [
    {
      title: "Opening Remarks",
      speaker: "Barry Whitehat (EF PSE) and gubsheep (0xPARC)",
      time: "4:15PM",
      description: ""
    },
    {
      title: "Finding bugs in ZK Circuits: An Exploration of Veridise Tools",
      speaker: "Jon Stephens (Veridise)",
      time: "4:25PM",
      description: "There has been increasing interest in integrating ZK Circuits into the blockchain stack to provide users with privacy. However, similar to smart contracts, it is likely that they will be high value targets for attackers as their exploitation could provide a monetary gain. It is therefore essential to develop tools and techniques to help developers increase the confidence in their circuits. In this talk, I will discuss ongoing work at Veridise capable of finding bugs in ZK circuits as well as lessons that we learn from our ongoing auditing on ZK circuits."
    },
    {
      title: "Zk Bug Tracker",
      speaker: "Kyle Charbonnet (EF PSE)",
      time: "4:55PM",
      description: "Short talk introducing the zk bug tracker repo on github. We'll explain how anyone can contribute."
    },
    {
      title: "Benchmarks for Formal Verification",
      speaker: "Michael Chu (0xPARC) and Jason Morton (zKonduit / 0xPARC)",
      time: "5:02PM",
      description: "We present a set of benchmarks and automated benchmarking tools for ZK formal verification practitioners."
    },
    {
      title: "Solving Polynomial Equation Systems over Large Prime Fields: a Review",
      speaker: "Lucas Vella (Ethereum Foundation)",
      time: "5:10PM",
      description: "Many ZK verification problems can be reduced to solving a system of polynomial equations over the ZK field. In this session I present the techniques I have reviewed over the past year on how to solve these kind of systems."
    },
    {
      title: "Break",
      speaker: "",
      time: "5:30PM",
      description: ""
    },
    {
      title: "[Panel] Auditing Strategy for zkEVM",
      speaker: "Barry Whitehat (EF PSE), with Haichen S (Scroll), Jordi Baylina (Polygon Hermez), and David T (EF)",
      time: "5:30PM",
      description: "A panel discussing approaches to auditing the zkEVM."
    }
  ];

  return (
    <Center>
      <Spacer height={4} />

      <Logos />

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <Bold>Applied ZK SLS</Bold>
      </div>
      <div style={{ textAlign: "center" }}>
        Bogotá, Colombia
      </div>
      <div style={{ textAlign: "center" }}>
        October 13, 2022
      </div>
      
      <Spacer height={1} />

      <TitleBarLinks />

      <Spacer height={1} />

      <PageContent>
        <div>
        <Bold>Update: Recordings for all Devcon ZK SLS sessions below are available <a href="https://www.youtube.com/watch?v=w7UAcsptyeo&t=2040s">here</a>. Future of Proving Systems begins at 34:00, ZK Applications Showcase begins at 3:39:05, and ZK Security begins at 6:44:54.</Bold>
        </div>

        <Spacer height={1} />

        <div>
          The ZK SLS at Devcon will take place on Thursday (10/13) from 10AM to 6PM. All sessions will take place at Workshop 1, on the <a href="https://app.devcon.org/venue/floor/floor-2">second floor</a> of the Agora Bogotá Convention Center.
        </div>

        <Spacer height={1} />

        <div>
          This SLS will feature three sessions: Future of Proving Systems (<a href="https://app.devcon.org/schedule/KPR8CL">add to your Devcon calendar</a>), ZK Applications Showcase (<a href="https://app.devcon.org/schedule/8QFEHB">add to your Devcon calendar</a>), and ZK Security (<a href="https://app.devcon.org/schedule/xmhca7">add to your Devcon calendar</a>).
        </div>

        <div style={{ textAlign: "left" }}>
          <h2>Sesson 1: Future of Proving Systems (10:00AM - 1:00PM)</h2>
        </div>

        {morning.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}
        
        <div style={{ textAlign: "left" }}>
          <h2>Session 2: ZK Applications Showcase (1:00PM - 4:00PM)</h2>
        </div>

        {earlyAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}

        <div style={{ textAlign: "left" }}>
          <h2>Session 3: ZK Security (4:15PM - 6:00PM)</h2>
        </div>

        {lateAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}
      </PageContent>

      <Spacer height={4} />

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  )
}

function DevconCommunityHub() {

  const lateAfternoon = [
    {
      title: "[Breakout] Formal methods for emerging ZK systems",
      speaker: "Led by Yu Feng (Veridise & UCSB)",
      time: "3:15PM",
      description: "In this session, we will discuss ongoing security challenges in emerging ZK systems. How do we define the correctness of a ZK circuit/system? How do we ensure that a given ZK circuit implementation is functionally correct? What are the most common bugs in ZK? We will brainstorm techniques (e.g., formal methods) that could partially mitigate the above challenges."
    },
    {
      title: "[Breakout] Aggregation: a new superpower",
      speaker: "Led by Barry Whitehat (Ethereum Foundation PSE)",
      time: "3:15PM",
      description: "We'll talk about ZK aggregation and recursion, discuss the state of the art, and figure out next steps."
    },
    {
      title: "[Breakout] Visualizing ZK Applications",
      speaker: "Led by Rachel Akerley (Ethereum Foundation PSE)",
      time: "3:15PM",
      description: "No matter what your technical background is, you are invited to share your experiences and challenges working on ZK applications. This discussion will be organized around questions related to collaborative and iterative design/build cycles. In what contexts have you built zk applications and how did the context effect design decisions? How has design thinking impacted your team in different phases of development? What tools, techniques or practices do you use to facilitate dialogue between contributors of different technical domains? What tradeoffs have you had to make for something to be usable and how was the decision made?"
    },
    {
      title: "STARKs in Circom",
      speaker: "Led by Jordi Baylina (Polygon Hermez)",
      time: "3:15PM",
      description: "I will explain how to create a STARK proof from a Circom circuit. I'll explain how PIL and Circom complement one each other to write more powerfull zkCircuits. This is the tooling we used to create the Polygon zkEVM."
    }
  ];

  return (
    <Center>
      <Spacer height={4} />

      <Logos />

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <Bold>ZK Community Hub</Bold>
      </div>
      <div style={{ textAlign: "center" }}>
        Bogotá, Colombia
      </div>
      <div style={{ textAlign: "center" }}>
        October 11-14, 2022
      </div>
      
      <Spacer height={1} />

      <TitleBarLinks />

      <Spacer height={1} />

      <PageContent>
        The ZK Community Hub is located on Floor 1 of the Agora Bogotá Convention Center, to the <a href="https://app.devcon.org/venue/floor/floor-1">immediate left</a> of the Main Entrance and Registration Area.

        This hub is a dedicated space at the Devcon venue for teams in the ZK ecosystem to run meetups, office hours, interactive demos, and more!

        <div style={{ textAlign: "left" }}>
          <h2>Tuesday 10/11</h2>
        </div>

        <CommunityHub1/>

        <div style={{ textAlign: "left" }}>
          <h2>Wednesday 10/12</h2>
        </div>

        <CommunityHub2/>

        <div style={{ textAlign: "left" }}>
          <h2>Thursday 10/13</h2>
        </div>

        <CommunityHub3/>

        <div style={{ textAlign: "left" }}>
          <h2>Friday 10/14</h2>
        </div>

        <CommunityHub4/>

      </PageContent>

      <Spacer height={4} />

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  )
}

function StanfordAgendaPage() {
  const morning = [
    {
      title: "Doors Open: Welcome and Coffee",
      speaker: "Ethereum Foundation PSE & 0xPARC",
      time: "9:30AM",
      description: "Doors open at 9:30AM. Join other participants for coffee and conversation!"
    },
    {
      title: "Opening Remarks",
      speaker: "Ethereum Foundation PSE & 0xPARC",
      time: "10:00AM",
      description: ""
    },
    {
      title: "ZK Circuits for Elliptic Curve Operations in Halo2",
      speaker: "Yi Sun, Jonathan Wang",
      time: "10:10AM",
      description: "We present halo2-ecc, a ZK circuit library for elliptic curve operations in halo2.  We will discuss our implementations of multi-scalar multiplication, optimal Ate pairing, and ECDSA verification on configurable curves including BN254 and Secp256k1.  Our library supports a configurable prover-verifier tradeoff, and we will show benchmarks for cheap verification and fast proving settings for tehse operations from the same codebase."
    },
    {
      title: "On-chain attestation issuance using ZK",
      speaker: "Leo Sayous (Sismo)",
      time: "10:35AM",
      description: "We discuss different trade-offs between ZK-powered attestation issuance mechanisms on chain."
    },
    {
      title: "Towards a feature-complete and backwards-compatible Privacy Layer for Ethereum",
      speaker: "Wei Dai (Bain Crypto Capital)",
      time: "11:00AM",
      description: "Ethereum EOAs and token standards (ERC20/721/1155) are terrible for end-user privacy. Can we build a privacy infrastructure *on-top of* existing token standards in the Ethereum ecosystem where user can use any ERC20/721/1155-compatible dApps anonymously? This talk explores necessary features of a privacy layer for Ethereum and short-comings of existing ones. Finally, a concrete design of a feature-complete, backwards-compatible privacy layer, codename Flax, is discussed."
    },
    {
      title: "ZK lets us play richer characters online",
      speaker: "Lakshman Sankar (Personae Labs/0xPARC)",
      time: "11:25AM",
      description: "This talk outlines a brief history of Personae Labs/0xPARC work on zk-identity (zk-identity -> cabal -> heyanon -> ...), some discussion around ongoing technical/research work and challenges, and some speculative discussion around the future of identity online."
    },
    {
      title: "SnarkyJS",
      speaker: "Martin Minkov (Mina)",
      time: "11:50AM",
      description: "SnarkyJS is a typescript framework for writing zkApps on Mina and more generally a high-level programming environment for describing zkSNARK circuits (including with recursion) in an easy, understandable manner. In this talk, we will touch on Mina and zkApps and showcase our SnarkyJS framework."
    },
    {
      title: "Recursive Groth16 SNARKs and Applications of Recursive SNARKs",
      speaker: "Nalin Bhardwaj (0xPARC)",
      time: "12:15PM",
      description: "I'll talk about building a groth16 verifier in Circom to enable SNARK recursion in groth16, and talk about the applications we've built using recursive SNARKs:\n- Isokratia: A governance platform that rolls up votes on-chain using succinct SNARK proofs\n- ETHdos: A fun social experiment uniquely enabled by composability properties of recursive SNARKs"
    },
  ];

  const earlyAfternoon = [
    {
      title: "Formal Verification for Zero-Knowledge Proofs",
      speaker: "Yanju Chen (Veridise & UCSB)",
      time: "1:15PM",
      description: "Recent efforts have made it possible to write zero-knowledge proofs without having deep expertise in cryptography. Nevertheless, these proofs can be subtly wrong and result in situations where the application erroneously “verifies” bogus information from an attacker. In this talk, we will give an overview of our research that can be used to reason about the correctness of zero-knowledge proofs and highlight some of the open-source tools that Veridise has developed to find bugs in ZK circuits, as well as proving their functional correctness."
    },
    {
      title: "Miden VM architecture overview",
      speaker: "Bobbin Threadbare (Polygon)",
      time: "1:40PM",
      description: "During this session, we'll go over basic components of Miden VM and describe how we've implemented them using the STARK proving system. We'll also provide a high-level overview of Miden VM features, and compare them against other ZK virtual machines."
    },
    {
      title: "A New ECDSA Nullifier Scheme for ZK",
      speaker: "Aayush Gupta (MIT & 0xPARC)",
      time: "2:05PM",
      description: "I describe a new way to define a noninteractive, provably unique, anonymous identifier for each ECDSA keypair, to solve the big issue of tiny initial anonymity sets. However, secrecy is broken when quantum algorithms can break ECDSA -- should we still push and standardize this?"
    },
    {
      title: "SNARK-Enabled Light Client Bridge",
      speaker: "Uma Roy, John Guibas (0xPARC)",
      time: "2:15PM",
      description: "The Ethereum 2.0 spec introduces the sync committee protocol, allowing for a lightweight light client implementation. We build a gas-efficient EVM light client by moving expensive computation, such as verifying validator signatures, off-chain using zkSNARKS. This requires implementing out-of-field arithmetic for the BLS12-381 curve. Our light client allows for arbitrary state proofs and message passing between two chains that implement Eth2 consensus. As an example, we showcase a prototype ERC-20 bridge from Ethereum to Gnosis Chain, which borrows the security guarantees of the sync committee."
    },
    {
      title: "Zero-Knowledge Machine Learning",
      speaker: "Jason Morton (ZKonduit & 0xPARC)",
      time: "2:35PM",
      description: "ZKML could be the key to giving the blockchain eyes, letting smart contracts exercise judgment, one-person oracles, and generally getting data on-chain in a scalable way. I'll discuss some recent progress using Halo2 for deep learning and the zkml roadmap."
    },
    {
      title: "Universal Reputation with ZKPs",
      speaker: "Chance Hudson (Ethereum Foundation PSE)",
      time: "2:50PM",
      description: "A quick overview of Unirep and a demo of Unirep Social."
    }
  ];

  const lateAfternoon = [
    {
      title: "[Breakout] Formal methods for emerging ZK systems",
      speaker: "Led by Yu Feng (Veridise & UCSB)",
      time: "3:15PM",
      description: "In this session, we will discuss ongoing security challenges in emerging ZK systems. How do we define the correctness of a ZK circuit/system? How do we ensure that a given ZK circuit implementation is functionally correct? What are the most common bugs in ZK? We will brainstorm techniques (e.g., formal methods) that could partially mitigate the above challenges."
    },
    {
      title: "[Breakout] Aggregation: a new superpower",
      speaker: "Led by Barry Whitehat (Ethereum Foundation PSE)",
      time: "3:15PM",
      description: "We'll talk about ZK aggregation and recursion, discuss the state of the art, and figure out next steps."
    },
    {
      title: "[Breakout] Visualizing ZK Applications",
      speaker: "Led by Rachel Akerley (Ethereum Foundation PSE)",
      time: "3:15PM",
      description: "No matter what your technical background is, you are invited to share your experiences and challenges working on ZK applications. This discussion will be organized around questions related to collaborative and iterative design/build cycles. In what contexts have you built zk applications and how did the context effect design decisions? How has design thinking impacted your team in different phases of development? What tools, techniques or practices do you use to facilitate dialogue between contributors of different technical domains? What tradeoffs have you had to make for something to be usable and how was the decision made?"
    },
    {
      title: "STARKs in Circom",
      speaker: "Led by Jordi Baylina (Polygon Hermez)",
      time: "3:15PM",
      description: "I will explain how to create a STARK proof from a Circom circuit. I'll explain how PIL and Circom complement one each other to write more powerfull zkCircuits. This is the tooling we used to create the Polygon zkEVM."
    }
  ];

  return (
    <Center>
      <Spacer height={4} />

      <Logos />

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <Bold>Applied ZK Workshop</Bold>
      </div>
      <div style={{ textAlign: "center" }}>
        Stanford University, CA
      </div>
      <div style={{ textAlign: "center" }}>
        September 2, 2022
      </div>
      
      <Spacer height={1} />

      <TitleBarLinks />

      <Spacer height={1} />

      <PageContent>
        Recordings from Applied ZK Workshop @ SBC are available <a href="https://www.youtube.com/playlist?list=PLNK7oFq6eaEwtFRrbZP5c12FMq9yygTzg">here</a>.

        <div style={{ textAlign: "left" }}>
          <h2>Morning Block (10:00AM - 12:30PM)</h2>
        </div>

        {morning.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}
        
        <div style={{ textAlign: "left" }}>
          <h2>Early Afternoon Block (1:15PM - 3:05PM)</h2>
        </div>

        {earlyAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}

        <div style={{ textAlign: "left" }}>
          <h2>Late Afternoon Block (3:15PM - 5:00PM)</h2>
        </div>

        {lateAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}
      </PageContent>

      <Spacer height={4} />

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  )
}

function AmsterdamAgendaPage() {
  const satMorning = [
    {
      title: "Opening Remarks",
      speaker: "gubsheep (0xPARC)",
      time: "10:00AM",
      description: ""
    },
    {
      title: "zkAttestor: Block and State Attestations on Ethereum",
      speaker: "Yi Sun",
      time: "10:20AM",
      description: "zkAttestor produces trustless zkSNARK-based on-chain attestations to data from any Ethereum state or block, trustlessly verified on-chain in a smart contract which caches historical block hashes. Use cases might include identity, dapp primitives involving history, and on-chain reputation."
    },
    {
      title: "Interep - Identity in the web3 world",
      speaker: "Geoff Lamperd and Cedoor (EF Privacy and Scaling Explorations)",
      time: "10:40AM",
      description: "Interep is an anti-sybil mechanism providing a bridge from an established identity source to the Ethereum ecosystem while preserving privacy. We'll discuss the potential use cases."
    },
    {
      title: "Unirep and Unirep Social",
      speaker: "Vivian Jeng and Barry Whitehat",
      time: "11:00AM",
      description: "Exploration of the cryptography primitives and data structures used in UniRep, a protocol enabling users to give, receive and prove reputation anonymously. We’ll also demo UniRep Social, a Reddit-like social app using the UniRep protocol."
    },
    {
      title: "Private, economic spam protection in Waku with RLN",
      speaker: "Oskar Thoren (Vac)", 
      time: "11:30AM",
      description: "We'll look at Waku, the communication layer for Web3, and how it uses ZK for its service network. Specifically, we'll look at RLN-RELAY, a privacy-preserving p2p economic spam protection mechanism. We'll also look briefly at the history of different implementations, and how we plan to get the best of both worlds with Rust and Circom using ark-circom and Zerokit."
    },
    {
      title: "[Demo] Cabal: anonymous internet clubs",
      speaker: "Lakshman Sankar and Uma Roy (0xPARC)",
      time: "11:50AM",
      description: "We're presenting cabal.xyz, a way to gate discord servers with ETH information while hiding your identity."
    },
    {
      title: "[Demo] ZK Card Game",
      speaker: "Abby Lu (ETHUni / 0xPARC)",
      time: "12:00PM",
      description: "Minion fight is a zero-knowledge incomplete-information card game where private information such as hands and decks are simulated on chain. Each player gets a set of cute minion cards with different abilities and battles against a friend!"
    },
    {
      title: "[Demo] ZK Data Marketplace",
      speaker: "Nick Ulven (ETHUni / 0xPARC)",
      time: "12:10PM",
      description: "Diving into the design of a generalized marketplace that can exchange any asset type. Introduce a new token interface for private data and a demo of its use."
    },
    {
      title: "[Demo] Stealthdrop",
      speaker: "Aayush Gupta, Nalin Bhardwaj (ETHUni / 0xPARC)",
      time: "12:20PM",
      description: "We present a demo, explanation, hack, and fix of stealthdrop, the first OSS zero knowledge airdrop protocol."
    },
    {
      title: "[Demo] A zkMNIST dApp",
      speaker: "Francis Ho (Walden Catalyst Ventures) and Horace Pan (PhD student, University of Chicago)",
      time: "12:30PM",
      description: "We introduce and demonstrate a dApp with a zkSNARK prover/verifier for a simple computer vision deep learning convolutional neural network for handwritten digit recognition (MNIST). This enables on-chain verification of off-chain, private data. Potential applications of this type of zk technology may include the protection of sensitive and private information such as personal financial info, personal biometric info, or private video/audio/image data."
    }
  ];

  const satAfternoon = [
    {
      title: "Breakout: Demo Q&A",
      speaker: "Demo Presenters",
      time: "1:30PM - 2:00PM",
      description: "Demo presenters from the morning session will be available for questions and followup discussion.",
    },
    {
      title: "Custom Gates in Circom",
      speaker: "Jordi Baylina (Polygon Hermez)",
      time: "1:30PM - 2:30PM",
      description: "We discuss an implementation of custom gates for UltraPLONK-like proving schemes in Circom."
    },
    {
      title: "Breakout: Path to ZK-identity",
      speaker: "Lakshman Sankar and Uma Roy (0xPARC)",
      time: "2:00PM - 3:00PM",
      description: "We want to bring together tools builders (i.e. blaine/jordi from snarkjs, erik rekmarks from metamask team) and app builders to discuss the path to making ZKPs about ethereum information from a web browser."
    },
    {
      title: "ZK App Boilerplate",
      speaker: "Wanseob (EF Privacy and Scaling Explorations)",
      time: "2:45PM - 3:15PM",
      description: "Hands-on session: a guide to build an applied zkp application."
    },
    {
      title: "Breakout: ZKID Primitives and Standards",
      speaker: "gubsheep (0xPARC) and Barry Whitehat (EF Privacy and Scaling Explorations)",
      time: "3:00PM - 4:00PM",
      description: "Following Lakshman and Uma's breakout session, we'll discuss standards for anonymity sets, ZK social mechanics, and the design of future identity registries."
    }
  ];

  const sunMorning = [
    {
      title: "RLN and RLN applications in the real world",
      speaker: "Blagoj Dimovski",
      time: "10:20AM",
      description: "We'll describe what is RLN, why it is important, as well as recent developments and real world applications."
    },
    {
      title: "UltraPLONK Arithmetisation",
      speaker: "Ying Tong Lai (Electric Coin Company)",
      time: "10:40AM",
      description: "UltraPLONK is an extension of PLONK that supports custom gates and lookup arguments. In this talk, we introduce abstractions such as the \"matrix\" picture of the circuit, regions, and gates; then, we break these down to understand how they translate into polynomials that constrain a witness. We also explain optimisations, like floor-planning and selector combination, that improve the circuit layout."
    },
    {
      title: "Break",
      speaker: "",
      time: "11:00AM"
    },
    {
      title: "Elliptic Curves, Pairings, and RSA in Circom",
      speaker: "Yi Sun, Steven Hao, and gubsheep (0xPARC)",
      time: "11:15AM",
      description: "We present implementations of ECDSA, elliptic curve pairings, and RSA in circom, as well as some of their applications."
    },
    {
      title: "Ecne: R1CS Soundness Verification",
      speaker: "Franklyn Wang (0xPARC)",
      time: "11:45AM",
      description: "Ecne is the first-ever soundness verifier for R1CS, a critical building block of zk-SNARKs."
    },
    {
      title: "Formal Verification for ZK Circuits Using Symbolic Compilation",
      speaker: "Yu Feng (UCSB / Veridise)",
      time: "12:00PM",
      description: "In this presentation, I will give a brief presentation for our ongoing project about formal verification for ZK circuits using symbolic compilation. I will show our preliminary results and potential directions for future work."
    },
    {
      title: "[Demo] zkREPL",
      speaker: "Kevin Kwok (0xPARC)",
      time: "12:20PM",
      description: "Presenting zkREPL, a fully in-browser development environment for zk-SNARKs written in Circom."
    }
  ]

  const sunAfternoon = [
    {
      title: "Workshop: Programming with UltraPLONK and Halo2",
      speaker: "Ying Tong Lai (Electric Coin Company) and Chih-Cheng Liang (EF Privacy and Scaling Explorations)  ",
      time: "1:30PM - 3:30PM",
      description: "Since its introduction, ECC's UltraPLONK implementation has been adopted by projects like zkEVM, DarkFi, and Orbis. In this workshop, we learn how to write simple gadgets in UltraPLONK and compose them into more complex circuits. In the process, we will gain a hands-on understanding of custom constraints, equality constraints, and lookup arguments. We will also explore developer tooling such as the MockProver and circuit printer."
    },
    {
      title: "Arithmetic Optimization Techniques for Circom/Groth16",
      speaker: "Yi Sun and gubsheep (0xPARC)",
      time: "1:30PM - 2:30PM",
      description: "We discuss some optimization techniques we used in implementing ECDSA in circom, which may be applicable to other large circuits."
    },
    {
      title: "Breakout: ZK Formal Verification",
      speaker: "Yu Feng (UCSB / Veridise)",
      time: "2:30PM - 3:30PM",
      description: "Introduce a list of relevant projects. Discussing ongoing challenges about applying formal verification to ZK."
    },
    {
      title: "Highly Flexible SNARKs",
      speaker: "Luke Pearson (Polychain Capital) and ZKGarage Contributors",
      time: "3:30PM - 4:30PM",
      description: "We will be walking through the design decisions and intrinsics of a modular PLONK library. Showcasing new tricks to compile arbitrary circuits."
    }
  ]

  return (
    <Center>
      <Spacer height={4} />

      <Logos />

      <Spacer height={1} />

      <div style={{ textAlign: "center" }}>
        <Bold>Applied ZK Workshop</Bold>
      </div>
      <div style={{ textAlign: "center" }}>
        Amsterdam
      </div>
      <div style={{ textAlign: "center" }}>
        April 23-24, 2022
      </div>
      
      <Spacer height={1} />

      <TitleBarLinks />

      <Spacer height={1} />

      <PageContent>
        <div style={{ textAlign: "left" }}>
          <h1>Devconnect Amsterdam ZK Workshop</h1>
        </div>

        <div style={{ textAlign: "left"}}>
          The <a href="https://devconnect.org/">Devconnect Amsterdam</a> edition of the Applied ZK Workshop took place on April 23-24. You can find recordings from this workshop <a href="https://www.youtube.com/playlist?list=PLJijNYoOwnssZzIIxfochRxo5QRW5Uvfg">here</a>.
        </div>
        <Spacer height={1}/>

        <div style={{ textAlign: "left" }}>
          <h2>April 23: Morning Session (10:00AM - 12:45PM)</h2>
        </div>

        {satMorning.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}
        
        <div style={{ textAlign: "left" }}>
          <h2>April 23: Afternoon Session (1:30PM - 5:00PM)</h2>
        </div>

        {satAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}

        <div style={{ textAlign: "left" }}>
          <h2>April 24: Morning Session (10:00AM - 12:30PM)</h2>
        </div>

        {sunMorning.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}

        <div style={{ textAlign: "left" }}>
          <h2>April 24: Afternoon Session (1:30PM - 5:00PM)</h2>
        </div>

        {sunAfternoon.map((e) => (<>
            <div style={{ textAlign: "left" }}>
              <Bold>{e.title}</Bold>
            </div>
            <div style={{ textAlign: "left" }}>
              {e.time} / {e.speaker}
            </div>
            <div style={{ textAlign: "left" }}>
              {e.description}
            </div>
            <Spacer height={1}/>
          </>
        ))}

      </PageContent>

      <Spacer height={4} />

      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  )
}

// function AboutPage() {
//   return (
//     <SubPage>
//       The 0xPARC Foundation promotes application-level innovation on Ethereum
//       and other decentralized platforms. Our primary areas of focus are:
//       <ul>
//         <li>
//           <Bold>Research and development.</Bold> We support experimental
//           decentralized applications that use novel technologies, such as
//           zero-knowledge cryptography. 0xPARC projects aim to push the
//           boundaries of what is technically possible with blockchain
//           applications.
//         </li>
//         <li>
//           <Bold>Open-source tools and infrastructure.</Bold> New application
//           design patterns require new tools and infrastrucutre. We encourage
//           development of these tools in accordance with open ecosystem values.
//         </li>
//         <li>
//           <Bold>Education and ecosystem development.</Bold> We aim to bring more
//           developers, technologists, writers, and thinkers to the frontiers of
//           decentralized application development. We do this through educational
//           programs and other community initiatives.
//         </li>
//       </ul>
//       Projects we fund, provide operational support for, or are otherwise
//       involved in include <Link href="https://blog.zkga.me/">Dark Forest</Link>,{" "}
//       <Link href="https://www.ethuniversity.org/">EthUniversity</Link>,{" "}
//       <Link href="https://reboothq.substack.com/">Reboot</Link>,{" "}
//       <Link href="https://github.com/projectsophon">Project Sophon</Link>, and
//       more.
//       <Spacer height={1} />
//       0xPARC aims to be timeboxed: we support projects without "owning" them,
//       and we hope to bootstrap an ecosystem of novel decentralized applications
//       by deploying talent and all of our money over the course of two years.
//       0xPARC is entirely funded by donations and grants from a number of
//       values-aligned organizations and entities, including:
//       <Spacer height={4} />
//       <Sponsors />
//       <Spacer height={4} />
//       <Center>
//         <span>
//           For any inquiries, email{" "}
//           <Link href="mailto:hello@0xPARC.org">hello@0xPARC.org</Link>
//         </span>
//       </Center>
//       <Spacer height={8} />
//     </SubPage>
//   );
// }

/**
 * All pages other than the landing page are wrapped in this component.
 */
// function SubPage({ children }: { children: React.ReactNode }) {
//   return (
//     <>
//       <TitleBar>
//         <a href="/">
//           <Logo />
//         </a>
//         <TitleBarLinks />
//       </TitleBar>
//       <Spacer height={2} />
//       <Center style={{ textAlign: "unset" }}>
//         <PageContent>{children}</PageContent>
//       </Center>
//       <MonkeyContainer>
//         <Monkey />
//       </MonkeyContainer>
//     </>
//   );
// }

/**
 * Links to the `about` and `blog` pages. When you are on one of those two pages, the link to the
 * page you are on becomes just text, not a link.
 */
function TitleBarLinks() {
  const currentPage = window.location.pathname;
  const isOnHomePage = /^\/$/.test(currentPage);
  const isOnDevconPage = /^\/bogota-agenda$/.test(currentPage);
  const isOnDevconCommunityHubPage = /^\/bogota-community-hub$/.test(currentPage);
  const isOnStanfordPage = /^\/stanford-agenda$/.test(currentPage);
  const isOnAmsterdamPage = /^\/amsterdam-agenda$/.test(currentPage);
  // const isOnBlogPage = /^\/blog$/.test(currentPage);

  return (
    <span>
      <Link href="/">Home and Info</Link>
      <Spacer width={1.5} />
      <Link href="/bogota-agenda">ZK Workshop (10/13)</Link>
      <Spacer width={1.5} />
      <Link href="/bogota-community-hub">ZK Community Hub</Link>
      {/*<Spacer width={1.5} />*/}
      {/*<Link href="/stanford-agenda">SBC '22</Link>*/}
      {/*<Spacer width={1.5} />*/}
      {/*<Link href="/amsterdam-agenda">Devconnect '22</Link>*/}
      {/*<Link href="http://eepurl.com/hLH6UP">subscribe</Link>*/}
    </span>
  );
}

/**
 * Content rendered when there's no page that matches the URL which the user has navigated to.
 */
function FourOhFour() {
  return (
    <>
      <Spacer height={6} />
      <Center style={{ fontSize: "10em", color: "#aaa" }}>404</Center>
      <Center>
        <Link href="/">home</Link>
      </Center>
    </>
  );
}

/**
 * A nice grid view of all our sponsors, which turns into a single-column-wide list on small
 * devices.
 */
// function Sponsors() {
//   return (
//     <SponsorsContainer>
//       <SponsorImgContainer>
//         <a href="https://ethereum.org/en/foundation/">
//           <img
//             src={require("../public/sponsors/ethereum_foundation_sponsor.png")}
//           />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <a href="https://blog.zkga.me/">
//           <img src={require("../public/sponsors/df_sponsor.png")} />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <a href="https://gitcoin.co/">
//           <img src={require("../public/sponsors/gitcoin_sponsor.png")} />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <a href="https://www.gather.town/">
//           <img
//             src={require("../public/sponsors/gather_sponsor.png")}
//             width="100"
//           />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <a href="https://www.xdaichain.com/">
//           <img src={require("../public/sponsors/gnosis_sponsor.png")} />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <a href="https://ethglobal.com/">
//           <img src={require("../public/sponsors/eth_global_sponsor.png")} />
//         </a>
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         {/* empty to center align the dark forest sponsor */}
//       </SponsorImgContainer>

//       <SponsorImgContainer>
//         <span style={{ fontSize: "80%" }}>
//           Anonymous #1 <br />
//           Anonymous #2
//         </span>
//       </SponsorImgContainer>
//     </SponsorsContainer>
//   );
// }
