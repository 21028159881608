import React from "react";
import styled, { css, createGlobalStyle } from "styled-components";

export const colors = {
  blue: "#00AEEF",
  orange: "#FBB040",
  pink: "#E7B0D1",
  purple: "#56479D",
};

export const MonkeyContainer = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
  display: inline-block;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  padding-right: 32px;
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 20px;

  @media only screen and (max-width: 1000px) {
    background-color: white;
    align-items: center;
    padding-left: 16px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const AlignHorizontally = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Bold = styled.span`
  font-family: "Neue Haas Unica W01 Regular";
  font-weight: bold;
`;

export const VerySmall = styled.span`
  font-size: 80%;
`;

export const Link = styled.a``;

export const PageContent = styled.div`
  width: 600px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    width: unset;
    padding-left: 48px;
    padding-right: 48px;
  }
`;

export const Spacer = styled.div`
  ${({ width, height }: { width?: number; height?: number }) => css`
    width: 1em;
    height: 1em;
    ${width && !height ? "display: inline-block;" : ""}
    ${width ? `width: ${width}em;` : ""}
    ${height ? `height: ${height}em;min-height:${height}em;` : ""}
  `}
`;

export const SponsorImgContainer = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // left column
  &:nth-child(2n + 1) {
    justify-content: flex-end;
  }

  // right column
  &:nth-child(2n + 2) {
    justify-content: flex-start;
  }

  img {
    max-height: 100px;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;

    // left column
  &:nth-child(2n + 1) {
    justify-content: flex-end;
  }

  // right column
  &:nth-child(2n + 2) {
    justify-content: flex-start;
  }

    img {
      max-height: 100px;
      margin-bottom: 32px;
    }
  }
`;

export const FullWidhtContainer = styled.div`
  width: 100%;
`

export const SponsorsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-column-gap: 25px;
`;

export const GlobalStyle = createGlobalStyle`
  /*
    This CSS resource incorporates links to font software which is the valuable copyrighted
    property of Monotype and/or its suppliers. You may not attempt to copy, install,
    redistribute, convert, modify or reverse engineer this font software. Please contact
    Monotype with any questions regarding Web Fonts:  http://www.fontshop.com
  */

  body {
    font-family: "Neue Haas Unica W01 Light";
    font-size: 1em;
    margin: 0;
  }

  a {
    color: ${colors.blue};
    font-weight: bold;
    cursor: pointer;
    transition: color 200ms;
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
      color: ${colors.orange};
    }
  }

  ul {
    list-style: circle;
  }

  h1, h2, h3 {
    font-family: "Neue Haas Unica W01 Regular";
  }

  p code {
    background-color: #fafafa;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid #888888;
  }

  li code {
    background-color: #fafafa;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid #888888;
  }

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  pre {
    border-radius: 4px;
    border: 1px solid #888888;
    background-color: #fafafa;
    padding: 8px;
    overflow-y: none;
    overflow-x: auto;
  }

  @media only screen and (max-width: 1000px) {
    body {
      font-size: 2em;
    }
  }
`;

// export function Logo() {
//   return (
//     <LogoContainer>
//       <img width="84" height="82" src={require("../public/logo.svg")} />
//     </LogoContainer>
//   );
// }

const LogoContainer = styled.div`
  display: inline-block;
  @media only screen and (max-width: 1000px) {
    img {
      width: calc(84px * 1.4);
      height: calc(82px * 1.4);
    }
  }
`;

export function Logos() {
  return (
    <SponsorsContainer>
      <SponsorImgContainer>
        <a href="https://ethereum.foundation/">
          <img
            src={require("../public/pse-artwork-black.svg")}
          />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://0xparc.org">
          <img src={require("../public/logo.svg")} />
        </a>
      </SponsorImgContainer>
    </SponsorsContainer>
  );
}

export function CommunityHub1() {
  return (
    <FullWidhtContainer>
      <img src={require("../public/community-hub-10-11.jpeg")} width="100%"/>
    </FullWidhtContainer>
  )
}
export function CommunityHub2() {
  return (
    <FullWidhtContainer>
      <img src={require("../public/community-hub-10-12.jpeg")} width="100%"/>
    </FullWidhtContainer>
  )
}
export function CommunityHub3() {
  return (
    <FullWidhtContainer>
      <img src={require("../public/community-hub-10-13.jpeg")} width="100%"/>
    </FullWidhtContainer>
  )
}
export function CommunityHub4() {
  return (
    <FullWidhtContainer>
      <img src={require("../public/community-hub-10-14.jpeg")} width="100%"/>
    </FullWidhtContainer>
  )
}

const LogoWithTextContainer = styled.div`
  display: inline-block;
  @media only screen and (max-width: 1000px) {
    img {
      width: calc(84px * 2.5);
      height: calc(104px * 2.5);
    }
  }
`;

export function Monkey() {
  return <img width="28" height="31" src={require("../public/monkey.svg")} />;
}
